import React from 'react';

const FormMessage = ({ type, floating, closeAction, children, icon, ...props }) => {
  return (
    <div
      className='c-form-message'
      data-type={type}
      data-floating={floating}
      {...props}
    >
      <div className="icon">
        <img src={icon} alt="" />
      </div>
      <div className="message">
        {children}
      </div>
      {closeAction && (
        <div className="close">
          <button onClick={closeAction}>
          <svg
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
              strokeLinejoin="round"
              strokeMiterlimit="2"
              clipRule="evenodd"
              viewBox="0 0 22 22"
            >
              <path
                fill="#fff"
                fillRule="nonzero"
                d="M22 19.168l-8.185-8.187L22 2.807 19.168 0l-8.182 8.179L2.81 0 0 2.81l8.186 8.196L0 19.19 2.81 22l8.203-8.192L19.193 22 22 19.168z"
              ></path>
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default FormMessage