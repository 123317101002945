import React from 'react';

const Button = ({ variant = 'primary', size, color, label, icon, mobileIconOnly, noUnderline, disabled, ...props }) => {
  return (
    <div
      className='c-button'
      data-variant={variant}
      data-color={color}
      data-size={size}
      data-mobile-icon-only={mobileIconOnly}
      data-disabled={disabled}
      data-no-underline={noUnderline}
      {...props}
    >
      {icon && 
        <img src={icon} alt="" />
      }
      <span className="label">
        {label}
      </span>
    </div>
  );
};

export default Button