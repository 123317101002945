import axios from 'axios';
import { useState, useEffect } from 'react';
import { getCsrfToken } from 'next-auth/client';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';

import GatewayLayout from '../../components/GatewayLayout';
import Button from '@ui/blueotter-camp/components/button';
import Form from '@ui/blueotter-camp/components/forms/form';
import FieldText from '@ui/blueotter-camp/components/forms/field-text';
import FormMessage from '@ui/blueotter-camp/components/message';


export default function Login({ csrfToken }) {
    const router = useRouter();
    const [formState, setFormState] = useState({
        type: 'login',
    });
    const [recovery, setRecovery] = useState({
        on: false,
        type: 'sending',
        title: '',
        message: 'A submeter...',
        link: false,
        linkLabel: '',
    });

    const { error } = router.query;

    const onRecoverySelect = () => {
        setFormState({ type: 'recovery' });
    };

    const handleRecoverySubmit = async (event) => {
        event.preventDefault();
        const { email, csrfToken } = event.target;
        setRecovery({ ...recovery, on: true });

        const { statusText, data } = await axios.post('/api/auth/reset', {
            email: email.value,
            csrfToken: csrfToken.value,
        });

        setRecovery({ on: true, ...data });

        if (data.type == 'success') {
            setTimeout(() => {
                router.push(data.link);
            }, 3000);
        }
    };

    const [store, setStore, removeStore] = useCookies(['blueotter_store']);

    useEffect(() => {
        setStore("blueotter_store", "", { path: "/", maxAge: 0 });
    }, []);

    return (
        <GatewayLayout>
            {formState.type === 'login' && (
                <form method="post" action="/api/auth/callback/credentials">
                    <input
                        name="csrfToken"
                        type="hidden"
                        defaultValue={csrfToken}
                    />
                    <Form>
                        <div className="row">
                            <FieldText
                                label="Email"
                                name="email"
                                mobileCentered
                                required
                            />
                        </div>
                        <div className="row">
                            <FieldText
                                label="Senha"
                                name="password"
                                type="password"
                                mobileCentered
                                required
                            />
                        </div>
                        <div className="row">
                            <button type="submit">
                                <Button label="Entrar" size="full" />
                            </button>
                        </div>
                        <div className="row links">
                            <p>
                                <a onClick={onRecoverySelect}>
                                    Perdeu a senha de acesso?
                                </a>
                            </p>
                        </div>
                        <div className="row">
                            {error && (
                                <FormMessage type="error">
                                    <h2>Erro!</h2>
                                    <p>{decodeURI(error)}</p>
                                </FormMessage>
                            )}
                        </div>
                    </Form>
                </form>
            )}
            {formState.type === 'recovery' && (
                <form onSubmit={handleRecoverySubmit}>
                    <input
                        name="csrfToken"
                        type="hidden"
                        defaultValue={csrfToken}
                    />
                    <div className="html-content centered">
                        <h2>Recuperação de senha</h2>
                        <p>
                            Para recuperar a sua senha, introduza o seu email.
                        </p>
                    </div>
                    <hr className="spacer" />
                    <Form>
                        <div className="row">
                            <FieldText
                                label="Email"
                                name="email"
                                mobileCentered
                                required
                                {...(recovery.type === 'error'
                                    ? { isError: 'isError' }
                                    : {})}
                                {...(recovery.type === 'error'
                                    ? { error: 'Email inválido' }
                                    : {})}
                            />
                        </div>
                        <div className="row">
                            <button type="submit">
                                <Button label="Recuperar senha" size="full" />
                            </button>
                        </div>
                        <div className="row links">
                            <p>
                                <a onClick={() => setFormState({ type: 'login' })}>
                                    Voltar
                                </a>
                            </p>
                        </div>
                        <div className="row">
                            {recovery.on && (
                                <FormMessage type={recovery.type}>
                                    <h2>{recovery.title}</h2>
                                    <p>{recovery.message}</p>
                                    {recovery.link && (
                                        <a
                                            onClick={() => {
                                                router.reload();
                                            }}
                                        >
                                            {recovery.linkLabel}
                                        </a>
                                    )}
                                </FormMessage>
                            )}
                        </div>
                    </Form>
                </form>
            )}
        </GatewayLayout>
    );
}

// This is the recommended way for Next.js 9.3 or newer
export async function getServerSideProps(context) {
    return {
        props: {
            csrfToken: await getCsrfToken(context),
        },
    };
}
