import LayoutGateway from '@ui/blueotter-camp/components/layout/gateway';

export default function GatewayLayout({ children }) {
    return (
        <LayoutGateway
            logo="/static/brand/logo-large.svg"
            background="/static/images/background/water.jpg"
            footer={
                <ul>
                    <li>
                        <a href="#">Política de privacidade</a>
                    </li>
                </ul>
            }
        >
            {children}
        </LayoutGateway>
    );
}
