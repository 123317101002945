import React from 'react';

const FieldText = ({ type = 'text', datepicker, mini, fullWidth, name, fieldId, mobileCentered, password, isError, label, placeholder, value = '', required, disabled, error, ...props }) => {
  return (
    <div
      className='c-field-text'
      data-password={type == 'password' ? '' : null}
      data-mobile-centered={mobileCentered}
      data-is-error={isError}
      data-is-disabled={disabled}
      data-mini={mini}
      data-full-width={fullWidth}
      {...props}
    >
      <label>
        <span className="label">{label}</span>
        <input
          type={type}
          placeholder={placeholder}
          defaultValue={value}
          name={name}
          id={fieldId}
          required={required}
          disabled={disabled}
        />
        {error &&
          <div className="error">{error}</div>
        }
      </label>
    </div>
  );
};

export default FieldText