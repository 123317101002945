import React from 'react';

const Form = ({ children, narrow, smallLabels, ...props }) => {
  return (
    <div
      className='c-form'
      data-narrow={narrow}
      data-small-labels={smallLabels}
      {...props}
    >
      {children}
    </div>
  );
};

export default Form