import React from 'react';

const Logo = ({ size, src, ...props }) => {
  return (
    <div
      className='c-logo'
      data-size={size}
      {...props}
    >
      <img src={src} alt="" />
    </div>
  );
};

export default Logo