import React from 'react';
import Logo from '../../logo';

const LayoutGateway = ({ children, footer, logo, background, ...props }) => {
  return (
    <div
    className='c-layout-gateway'
    {...props}
    >
      <div className="background">
        <img src={background} alt="" />
      </div>
      <div className="main">
        <div className="logo">
          <Logo src={logo} />
        </div>
        <div className="content">
          { children }
        </div>
        <div className="footer">
          { footer }
        </div>
      </div>
    </div>
  );
};

export default LayoutGateway